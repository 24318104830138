import React from "react"
import { useMissions } from "../../../hooks/useMissions"
import { Paper, Radio, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import { SearchForm } from "../../../components/searchBar/SearchForm";
import { useCountMissions } from "../../../hooks/useCountMissions";
import { Column } from "../../../interfaces/common/IColumn";
import { ModalForm } from "../../../components/modalForms/ModalForm";
import { MissionForm } from "../../../components/form/mission-form/MissionForm";
import { useFormContainer } from "../../../hooks/useFormContainer";

export const MissionList = () => {
    const { rowsPerPage, page, handleChangePage, handleChangeRowsPerPage, missionsArray, searchMissionsByEntreprises, handleRadioChange, selectionModel } = useMissions();
    const { count } = useCountMissions();
    const { handleChangeMissionSelected } = useFormContainer()

    const columns: readonly Column[] = [
        {
            id: 'radiobutton', label: '', minWidth: 100, renderCell: (params) =>
                <Radio
                    checked={selectionModel.includes(params.id)}
                    onChange={(event) => handleRadioChange(event, params.id)}
                />
        },
        { id: 'poste', label: 'poste', minWidth: 170 },
    ];

    return (
        <div className="container-display">
            <Paper sx={{ width: '100%', overflow: 'hidden', height: "100%" }}>
                <div className="search-create-container">
                    <SearchForm
                        onChange={(e) => searchMissionsByEntreprises((e.target.value), 0)}
                    />
                    <ModalForm
                        children={<MissionForm />}
                    />
                </div>
                <TableContainer >
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                        sx={{
                                            backgroundColor: "#ECCC1E",
                                            color: "white"
                                        }}
                                    >
                                        {column.id === 'radiobutton' ? '' : column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {missionsArray
                                .map((row: { [x: string]: any; code: React.Key | null | undefined }) => {
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                            {columns.map((column) => (
                                                <TableCell key={column.id} align={column.align}>
                                                    {column.id === 'radiobutton' ? (
                                                        <Radio
                                                            checked={selectionModel.includes(row.id)}
                                                            onChange={(event) => {
                                                                handleChangeMissionSelected(event, { _id: row._id, poste: row.poste })
                                                                handleRadioChange(event, row.id)
                                                            }}
                                                        />
                                                    ) : (
                                                        column.format && typeof row[column.id] === 'number'
                                                            ? column.format(row[column.id])
                                                            : row[column.id]
                                                    )}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    );
                                })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10]}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    )
}