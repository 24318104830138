import React from "react"
import "./ListLeft.scss"
import "../../index.scss"
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { CANDIDATS_LABEL, ENTERPRISES_LABEL, MISSIONS_LABEL, FACTURES_LABEL } from "../../_const";
import { useLeftMenu } from "../../hooks/useLeftMenu";
import BusinessIcon from '@mui/icons-material/Business';
import ArticleIcon from '@mui/icons-material/Article';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
// @ts-ignore 
import logo from "../../assets/Images/brisin-logo-white.webp"
import Button from "@mui/material/Button";
import { useLogout } from "../../hooks/useLogout";

export const ListLeft = () => {
    const { value, handleChange, a11yProps, disableMission, disableHours, isDisplay } = useLeftMenu()
    const { handleLogout } = useLogout()

    return (
        <div className={isDisplay}>
            <img src={logo} alt="logo brisintemporal" />
            <Tabs
                orientation="vertical"
                value={value}
                TabIndicatorProps={{
                    sx: { backgroundColor: "#ECCC1E" }
                }}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{
                    borderRight: 1, borderColor: 'divider', height: "100%", paddingTop: "68px"
                }}>
                <Tab icon={<SupervisedUserCircleIcon />} iconPosition="start" label={CANDIDATS_LABEL} {...a11yProps(0)} />
                <Tab icon={<BusinessIcon />} iconPosition="start" label={ENTERPRISES_LABEL} {...a11yProps(1)} />
                <Tab icon={<CrisisAlertIcon />} iconPosition="start" label={MISSIONS_LABEL} {...a11yProps(2)} disabled={disableMission} />
                <Tab icon={<ArticleIcon />} iconPosition="start" label={FACTURES_LABEL} {...a11yProps(3)} disabled={disableHours} />
            </Tabs>
            <Button onClick={() => console.log("logout")} sx={{ position: "absolute", backgroundColor: "#ECCC1E", bottom: "68px", left: "50%", transform: "translate(-50%,-50%)" }}>
                <i className="fa-solid fa-right-from-bracket"></i>
                <span onClick={handleLogout}> Deconnexion</span>
            </Button>
        </div >
    )
}