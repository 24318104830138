import { DEFAULT_ERROR_MESSAGE, DEFAULT_SUCCESS_STATUS } from '../../../_const';
import ServerResponse from '../../../interfaces/common/ServerResponse';
import { createCandidatManager, getCandidatsFiltered, getCandidatsIdAndLabel, getCountAllCandidats } from '../../../manager/candidat';
import { CANDIDATS_LOADING, CANDIDATS_SUCCESS, CANDIDATS_FAILURE, CANDIDATS_FILTERED, CANDIDATS_UPDATED } from './actionTypes';
import { ICandidatCreate } from '../../../interfaces/ICandidatCreate'
import { changeMessageSnack } from '../Snack/actions';

export const getCandidats = (page: any): any => async (dispatch: any) => {
    try {
        dispatch({ type: CANDIDATS_LOADING });
        const response: ServerResponse = await getCandidatsIdAndLabel(page);
        if (response.status !== DEFAULT_SUCCESS_STATUS) {
            return dispatch({
                type: CANDIDATS_FAILURE,
                payload: response
            });
        }
        return dispatch({
            type: CANDIDATS_SUCCESS,
            payload: response.data
        })
    } catch (error) {
        console.log("error getCandidats: ", error);
        return dispatch({
            type: CANDIDATS_FAILURE,
            payload: {
                message: DEFAULT_ERROR_MESSAGE
            }
        });
    }
}

export const searchCandidat = (filter: any, page: any): any => async (dispatch: any) => {
    try {
        dispatch({ type: CANDIDATS_LOADING });
        const response: ServerResponse = await getCandidatsFiltered(filter, page);
        if (response.status !== DEFAULT_SUCCESS_STATUS) {
            return dispatch({
                type: CANDIDATS_FAILURE,
                payload: response
            });
        }
        return dispatch({
            type: CANDIDATS_FILTERED,
            payload: response.data
        })
    } catch (error) {
        console.log("error searchCandidat: ", error);
        return dispatch({
            type: CANDIDATS_FAILURE,
            payload: {
                message: DEFAULT_ERROR_MESSAGE
            }
        });
    }
}

export const createCandidat = (data: ICandidatCreate): any => async (dispatch: any) => {
    try {
        dispatch({ type: CANDIDATS_LOADING });
        const response: ServerResponse = await createCandidatManager(data);
        dispatch(changeMessageSnack(response.message))
        if (response.status !== DEFAULT_SUCCESS_STATUS) {
            return dispatch({
                type: CANDIDATS_FAILURE,
                payload: response
            });
        }
        return dispatch({
            type: CANDIDATS_UPDATED,
            payload: response.data
        })

    } catch (error) {
        return dispatch({
            type: CANDIDATS_FAILURE,
            payload: {
                message: DEFAULT_ERROR_MESSAGE
            }
        });
    }
}