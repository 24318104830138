import { CANDIDAT_DESELECTED, CANDIDAT_SELECTED } from "../../../../_const";
import { ICandidatSelected } from "../../../../interfaces/ICandidatSelected";

export const dispatchSelectedCandidat = (data: ICandidatSelected): any => (dispatch: any) => {
    try {
        if (!data) {
            return dispatch({
                type: CANDIDAT_DESELECTED,
                payload: data
            });
        }
        return dispatch({
            type: CANDIDAT_SELECTED,
            payload: data
        })
    } catch (error) {
        console.log("error : ", error);
    }
}

