import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom'
import Layout from '../Layouts/Layout';
import E404 from './E404/E404';
import Login from './Login/Login';
import { useConnected } from '../hooks/useConnected'
import { Candidats } from './Candidat/CandidatList/Candidats';
import { Enterpriselist } from './Enterprise/EnterpriseList/EnterpriseList';
import { MissionList } from './Mission/MissionList/Mission';
import { Hours } from './Hours/Hours';

const RequireAuth = ({ children, redirectToHome }: { children: JSX.Element, redirectToHome?: boolean }) => {
    const isUser = useConnected();
    if (!isUser.user && !redirectToHome) {
        return <Navigate to="/login" replace />;
    }

    return children;
}

const PublicRouter: React.FC = () => {

    return (
        <Routes>
            <Route element={<Layout />}>
                <Route path="/login" element={<RequireAuth redirectToHome><Login /></RequireAuth>} />
                <Route path="/candidats" element={<RequireAuth><Candidats /></RequireAuth>} />
                <Route path="/entreprises" element={<RequireAuth><Enterpriselist /></RequireAuth>} />
                <Route path="/missions" element={<RequireAuth><MissionList /></RequireAuth>} />
                <Route path="/hours-form" element={<RequireAuth><Hours /></RequireAuth>} />
                <Route path="" element={<Navigate to="/login" />} />
                <Route path="*" element={<E404 />} />
            </Route>
        </Routes>
    );
};

export default PublicRouter;