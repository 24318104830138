import { IEnterpriseCreate } from "../interfaces/IEnterpriseCreate";
import ServerResponse from "../interfaces/common/ServerResponse";
import { createRequest } from "../lib/create-request";

export const getEntreprisesIdAndLabel = async (page: any) => {
    return createRequest().get<ServerResponse>(`/enterprise/selectsEnterprises?page=${page}`, {});
}

export const createEnterpriseManager = async (data: IEnterpriseCreate) => {
    return createRequest().post<ServerResponse>('/enterprise/createEntreprise', {
        body: data
    })
}

export const getEntreprisesFiltered = async (filter: string, page: any) => {
    const result = createRequest().get<ServerResponse>(`/enterprise/entreprise/${filter}?page=${encodeURIComponent(page)}`);
    return result
}

export const getCountAllEntreprises = async (filter?: string) => {
    return createRequest().get<ServerResponse>('/enterprise/count/' + filter, {});
}