import { DEFAULT_ERROR_MESSAGE, DEFAULT_SUCCESS_STATUS } from "../../../_const";
import { IDocumentCreate } from "../../../interfaces/IDocumentCreate";
import ServerResponse from "../../../interfaces/common/ServerResponse";
import { createDocumentManager } from "../../../manager/document";
import { changeMessageSnack } from "../Snack/actions";
import { DOCUMENT_FAILURE, DOCUMENT_LOADING, DOCUMENT_SUCCESS } from "./actionTypes";

export const generateDocByData = (data: IDocumentCreate): any => async (dispatch: any) => {
    try {
        dispatch({ type: DOCUMENT_LOADING });
        const response: ServerResponse = await createDocumentManager(data);
        dispatch(changeMessageSnack(response.message))
        if (response.status !== DEFAULT_SUCCESS_STATUS) {
            return dispatch({
                type: DOCUMENT_FAILURE,
                payload: response
            });
        }
        return dispatch({
            type: DOCUMENT_SUCCESS,
            payload: response.data
        })
    } catch (error) {
        return dispatch({
            type: DOCUMENT_FAILURE,
            payload: {
                message: DEFAULT_ERROR_MESSAGE
            }
        });
    }
}