import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT, AUTH_REQUEST, AUTH_FAILURE, AUTH_RESPONSE } from './actionTypes';
import { AuthState } from '../../../interfaces/IAuth';

const initialState: AuthState = {
    isAuthenticated: true,
    user: null,
    loading: false,
    error: null,
};

export const authReducer = (state = initialState, action: any): AuthState => {
    switch (action.type) {
        case LOGIN_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload,
                loading: false,
                error: null,
            };
        case LOGIN_FAILURE:
            return {
                ...state,
                isAuthenticated: false,
                user: null,
                loading: false,
                error: action.error,
            };
        case LOGOUT:
            return {
                ...state,
                isAuthenticated: false,
                user: null,
                loading: false,
                error: null,
            };
        case AUTH_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            }
        case AUTH_RESPONSE:
            return {
                ...state,
                isAuthenticated: action.payload,
                loading: false,
                error: null,
            }
        case AUTH_FAILURE:
            return {
                ...state,
                isAuthenticated: false,
                loading: true,
                error: action.error,
            }
        default:
            return state;
    }
};