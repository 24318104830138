import { IMissionCreate } from "../interfaces/IMissionCreate";
import ServerResponse from "../interfaces/common/ServerResponse";
import { createRequest } from "../lib/create-request";

export const getMissionsById = async (entrepriseId: any, page: any) => {
    return createRequest().get<ServerResponse>(`/mission/selectsMissionsById/${entrepriseId}?page=${page}`);
}

export const createMissionManager = async (data: IMissionCreate) => {
    return createRequest().post<ServerResponse>('/mission/createMission', {
        body: data
    })
}

export const getMissionsEntreprisesFiltered = async (entrepriseId: any, filter: string, page: any) => {
    const shadowFilter = { entrepriseId, search: filter };
    const result = createRequest().get<ServerResponse>(`/mission/mission/${encodeURIComponent(JSON.stringify(shadowFilter))}?page=${encodeURIComponent(page)}`);
    return result
}

export const getCountAllMissions = async (entrepriseId: string, filter?: string) => {
    return createRequest().get<ServerResponse>('/mission/count/' + entrepriseId + '/' + filter);
}
