import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import React from "react"
import { BasicButton } from "../../button/Button"
import { useEntrepriseForm } from "../../../hooks/useEntrepriseForm";
import "./EntrepriseForm.scss"


export const EnterpriseForm: React.FC<any> = ({ handleClose }) => {
    const entreprise = useEntrepriseForm()
    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
        >
            <div className="fields-container-entreprise">
                {entreprise.fields.map((field: any) => (
                    <TextField
                        key={field.id}
                        id={field.id}
                        label={field.label}
                        onChange={(e) => {
                            entreprise.setFieldData({ fieldId: field.id, label: e.target.value })
                        }
                        }
                    />
                ))}
            </div>
            <BasicButton id="save" variant="contained" label="Sauvegarder" onClick={() => {
                entreprise.createEnterpriseDispatch()
                handleClose()
            }
            } />
        </Box>
    )
}