import { useDispatch, useSelector } from "react-redux";
import { generateDocByData } from "../_redux/reducers/Document/actions";
import { useEffect, useState } from "react";

export const useGenerateDoc = () => {
    const dispatch = useDispatch();
    const candidatId = useSelector((state: any) => state.candidatSelected._id)
    const entrepriseId = useSelector((state: any) => state.entrepriseSelected._id)
    const missionId = useSelector((state: any) => state.missionSelected._id)
    const factureData = useSelector((state: any) => state.workTime.data)
    const urlDocumentGeneratedStored = useSelector((state: any) => state.document.data)
    const [url, setUrl] = useState(null)

    useEffect(() => {
        if (urlDocumentGeneratedStored && urlDocumentGeneratedStored.path) {
            setUrl(urlDocumentGeneratedStored.path)
        }
    }, [urlDocumentGeneratedStored])

    useEffect(() => {
        if (url) {
            const link = document.createElement('a');
            link.href = url;
            link.click();
        } else {
            console.error('L\'URL du document généré n\'est pas disponible.');
        }
    }, [url]);


    const generateDoc = async (type: String) => {
        const data = {
            type: type,
            candidatId: candidatId,
            entrepriseId: entrepriseId,
            missionId: missionId,
            workTime: factureData
        }
        await dispatch(generateDocByData(data));
    }

    return {
        generateDoc
    }
}