import { useState } from "react";
import { useDispatch } from "react-redux";
import { dispatchSelectedCandidat } from "../_redux/reducers/Select/candidat/actions";
import { dispatchSelectedEntreprise } from "../_redux/reducers/Select/entreprise/actions";
import { dispatchSelectedMission } from "../_redux/reducers/Select/mission/actions";

export const useFormContainer = () => {
    const [activeForm, setActiveForm] = useState('candidat');
    const dispatch = useDispatch();

    const handleChangeCandidatSelected = (_event: any, newValue: any) => {
        dispatch(dispatchSelectedCandidat(newValue))
    }
    const handleChangeEntrepriseSelected = (_event: any, newValue: any) => {
        dispatch(dispatchSelectedEntreprise(newValue))
    }
    const handleChangeMissionSelected = (_event: any, newValue: any) => {
        dispatch(dispatchSelectedMission(newValue))
    }

    return {
        activeForm,
        setActiveForm,

        handleChangeCandidatSelected,
        handleChangeEntrepriseSelected,
        handleChangeMissionSelected
    }
}