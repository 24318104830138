import React from "react";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import { ITimeWeekForm } from "../../../interfaces/ITimeWeekForm";
import "./TimeWeekForm.scss"

export const TimeWeekForm: React.FC<ITimeWeekForm> = ({ blockIndex, updateBlockData, indexOfErrors, week }) => {
    return (
        <div className="column container-week-form">
            <h2>{week.title}</h2>
            <DatePicker
                sx={indexOfErrors.includes(blockIndex) && {
                    border: '1px solid red',
                    borderRadius: '4px',
                }}
                value={week.begin_date || null}
                label="Date d'entrée"
                format="DD-MM-YYYY"
                onChange={(newValue: any) => {
                    if (newValue && newValue.$y && newValue.$M || newValue.$M === 0 && newValue.$D) {
                        updateBlockData(blockIndex, 'begin_date', new Date(newValue.$y, newValue.$M, newValue.$D));
                    }
                }}
            />
            <DatePicker
                sx={indexOfErrors.includes(blockIndex) && {
                    border: '1px solid red',
                    borderRadius: '4px',
                }}
                value={week.end_date || null}
                label="Date de fin"
                format="DD-MM-YYYY"
                onChange={(newValue: any) => {
                    if (newValue && newValue.$y && newValue.$M || newValue.$M === 0 && newValue.$D) {
                        updateBlockData(blockIndex, 'end_date', new Date(newValue.$y, newValue.$M, newValue.$D));
                    }
                }} />
            <TextField id="outlined-basic" variant="outlined" type="number"
                value={week && week.hours_count || null}
                label="Nb heures travaillées"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    updateBlockData(blockIndex, 'hours_count', parseInt(event.target.value));
                }} />
        </div>
    )
}