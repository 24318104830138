import React from 'react';
import { Routes, Route } from 'react-router-dom';
import PublicRouter from './pages/Public.router'

const App: React.FC = () => {
  return (
    <Routes>
      <Route path="/*" element={<PublicRouter />} />
    </Routes>
  )
}

export default App;