const { TEXTAREA_FORM_CONDITION, DATE_FORM_CONDITION, NUMBER_FORM_CONDITION, SELECT_FORM_CONDITION } = require("../../_const")
const candidatFields = [
    { id: "name", label: "Prénom" },
    { id: "surname", label: "Nom" },
    { id: "nationality", label: "Nationalité" },
    { id: "date_birthday", label: "Date de naissance", type: DATE_FORM_CONDITION },
    { id: "card_number_citizen", label: "Carte citoyen" },
    { id: "citizen_date_to", label: "Date de validité (carte citoyen)", type: DATE_FORM_CONDITION },
    { id: "fiscal_identity_number", label: "Identité fiscal" },
    { id: "profession", label: "profession" },
    { id: "adress", label: "Adresse" },
    { id: "cp", label: "Code postal" },
    { id: "phone", label: "Téléphone" },
    { id: "niss", label: "NISS" },
];
const entrepriseFields = [
    { id: "name_enterprise", label: "Raison sociale" },
    { id: "siret_number", label: "SIRET" },
    { id: "intra_number", label: "TVA Intracommunautaire" },
    { id: "office_phone", label: "Téléphone fixe" },
    { id: "portable_phone", label: "Téléphone portable" },
    { id: "adress_enterprise", label: "Adresse postale" },
    { id: "responsable_name", label: "Nom du dirigeant" },
    { id: "idcc", label: "IDCC" },
];

const optionsHours = [{ value: 1, label: 1 }, { value: 2, label: 2 }, { value: 3, label: 3 }, { value: 4, label: 4 }, { value: 5, label: 5 }, { value: 6, label: 6 }, { value: 7, label: 7 }, { value: 8, label: 8 }, { value: 9, label: 9 }, { value: 10, label: 10 }, { value: 11, label: 11 }, { value: 12, label: 12 }, { value: 13, label: 13 }, { value: 14, label: 14 }, { value: 15, label: 15 }, { value: 16, label: 16 }, { value: 17, label: 17 }, { value: 18, label: 18 }, { value: 19, label: 19 }, { value: 20, label: 20 }, { value: 21, label: 21 }, { value: 22, label: 22 }, { value: 23, label: 23 }, { value: 24, label: 24 }]

const missionFields = [
    { id: "motif", label: "Motif", type: TEXTAREA_FORM_CONDITION },
    { id: "begin_date", label: "Date début de mission", type: DATE_FORM_CONDITION },
    { id: "end_date", label: "Date fin de mission", type: DATE_FORM_CONDITION },
    { id: "description", label: "Description", type: TEXTAREA_FORM_CONDITION },
    { id: "qualification_prof_number", label: "Grade de qualification", type: NUMBER_FORM_CONDITION },
    { id: "salaire", label: "Montant du salaire", type: "Number" },
    { id: "poste", label: "Poste exercé" },
    { id: "risk", label: "Risque ?", type: SELECT_FORM_CONDITION, options: [{ value: true, label: "Oui" }, { value: false, label: "Non" }] },
    { id: "contract_date", label: "Date du contract", type: DATE_FORM_CONDITION },
    { id: "level", label: "Niveau", type: NUMBER_FORM_CONDITION },
    { id: "range", label: "Tranche", type: NUMBER_FORM_CONDITION },
    { id: "time_week", label: "Nombre d'heure/semaine", type: NUMBER_FORM_CONDITION },
    { id: "brut_hour", label: "Montant brut/heure", type: NUMBER_FORM_CONDITION },
    { id: "begin_time_morning", label: "De (matin)", type: SELECT_FORM_CONDITION, options: optionsHours },
    { id: "end_time_morning", label: "À (matin)", type: SELECT_FORM_CONDITION, options: optionsHours },
    { id: "begin_time_afternoon", label: "De (après-midi)", type: SELECT_FORM_CONDITION, options: optionsHours },
    { id: "end_time_afternoon", label: "À (après-midi)", type: SELECT_FORM_CONDITION, options: optionsHours },
    { id: "complet_time", label: "Temps mensuel", type: NUMBER_FORM_CONDITION },
    { id: "location_mission", label: "Lieu de mission" },
    { id: "signature_date", label: "Date signature de contract", type: DATE_FORM_CONDITION },
    { id: "pourcentage1", label: "Pourcentage (1)", type: NUMBER_FORM_CONDITION },
    { id: "fromHsTime1", label: "À partir de (heure) - (1)", type: NUMBER_FORM_CONDITION },
    { id: "pourcentage2", label: "Pourcentage (2)", type: NUMBER_FORM_CONDITION },
    { id: "fromHsTime2", label: "À partir de (heure) - (2)", type: NUMBER_FORM_CONDITION },
]

const documents = [
    { name: 'Mix' },
    { name: 'Facture heures' },
    { name: 'Contrat mise à disposition' },
    { name: 'Contrat de travail' },
    { name: 'Accord de recrutement' },
    { name: 'Contrat de non responsabilité' },
]

module.exports = {
    candidatFields,
    entrepriseFields,
    missionFields,
    documents
}