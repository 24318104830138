import React, { useEffect, useState } from "react";
import './Bottom.scss'
import { Preview } from "../../components/preview/Preview";
import { useSelector } from "react-redux";

export const Bottom: React.FC = () => {
    const [isDisplayBottom, setIsDisplayBottom] = useState<string>("bottom-container")
    const locationPathname = useSelector((state: any) => state.router.location)

    useEffect(() => {
        if (locationPathname.pathname)
            if (locationPathname.pathname === "/login") {
                setIsDisplayBottom("hidden")
            } else setIsDisplayBottom("bottom-container")
    }, [locationPathname.pathname])

    return (
        <div className={isDisplayBottom}>
            <Preview />
        </div>
    )
}

