import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getEnterprises, searchEntreprise } from "../_redux/reducers/Entreprise/actions";
import { changeFilter } from "../_redux/reducers/filter/actions";
import { dispatchSelectedMission } from "../_redux/reducers/Select/mission/actions";
import { restartWeekTime } from "../_redux/reducers/WorkTime/actions";

export const useEnterprises = () => {
    const entreprises = useSelector((state: any) => state.entreprises.data);
    const dispatch = useDispatch();
    const [entreprisesArray, setEntreprisesArray] = useState<any>([]);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [page, setPage] = useState<any>(0);
    const [selectionModel, setSelectionModel] = useState<string[]>([]);
    const filter = useSelector((state: any) => state.filter.filter)
    const locationPath = useSelector((state: any) => state.router.location.pathname)
    const entrepriseSelected = useSelector((state: any) => state.entrepriseSelected._id)

    useEffect(() => {
        if (!filter) {
            dispatch(getEnterprises(page))
        } else {
            dispatch(searchEntreprise(filter, page))
        }
    }, [page])

    useEffect(() => {
        setPage(0)
    }, [filter])

    useEffect(() => {
        dispatch(changeFilter(""))
        dispatch(getEnterprises(page))
    }, [locationPath])

    useEffect(() => {
        if (entreprises)
            setEntreprisesArray(entreprises.map((entry: any) => {
                return {
                    ...entry,
                    id: entry._id
                }
            }));
    }, [entreprises]);

    useEffect(() => {
        setSelectionModel(entrepriseSelected)
        dispatch(dispatchSelectedMission())
        dispatch(restartWeekTime())
    }, [entrepriseSelected])

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
        const isChecked = event.target.checked;
        setSelectionModel(isChecked ? [id] : [])
    };

    const handleChangePage = (event: any, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const searchEntreprises = (filter: string, page: number) => {
        dispatch(changeFilter(filter))
        if (!filter) {
            dispatch(getEnterprises(page));
        } else {
            dispatch(searchEntreprise(filter, page));
        }
    };

    return {
        entreprises,
        searchEntreprises,
        entreprisesArray,
        page,
        rowsPerPage,
        handleChangePage,
        handleChangeRowsPerPage,
        handleRadioChange,
        selectionModel
    }
}