import { IEnterpriseCreate } from "../../../../interfaces/IEnterpriseCreate";

const initialState: IEnterpriseCreate = {
    name_enterprise: "",
    siret_number: "",
    intra_number: "",
    office_phone: "",
    portable_phone: "",
    adress_enterprise: "",
    responsable_name: "",
    idcc: "",
};



export const enterpriseReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case "name_enterprise":
            return {
                ...state,
                name_enterprise: action.payload.label
            };
        case "siret_number":
            return {
                ...state,
                siret_number: action.payload.label
            };
        case "intra_number":
            return {
                ...state,
                intra_number: action.payload.label
            };
        case "office_phone":
            return {
                ...state,
                office_phone: action.payload.label
            };
        case "portable_phone":
            return {
                ...state,
                portable_phone: action.payload.label
            };
        case "adress_enterprise":
            return {
                ...state,
                adress_enterprise: action.payload.label
            };
        case "responsable_name":
            return {
                ...state,
                responsable_name: action.payload.label
            };
        case "idcc":
            return {
                ...state,
                idcc: action.payload.label
            };
        default:
            return state;
    }
}