import { DOCUMENT_LOADING, DOCUMENT_SUCCESS, DOCUMENT_FAILURE } from './actionTypes'
import { ISelectReduxState } from '../../../interfaces/ISelectReduxState';

const initialState: ISelectReduxState = {
    data: null,
    loading: false,
    error: null,
};

export const documentReducer = (state = initialState, action: any): ISelectReduxState => {
    switch (action.type) {
        case DOCUMENT_LOADING:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case DOCUMENT_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: null,
            };
        case DOCUMENT_FAILURE:
            return {
                ...state,
                data: null,
                loading: false,
                error: action.error,
            };
        default:
            return state;
    }
};