import axios from 'axios';

export const loadProxyUrl = () => {
    if (window.location.host.includes('localhost')) {
        return 'http://localhost:8080';
    }
    return 'https://api.ghumanos.brisintemporal.com';
};

const AxiosConfig = axios.create({
    withCredentials: true,
    baseURL: `${loadProxyUrl()}/api/`,
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
    },
    proxy: {
        host: `${loadProxyUrl()}/`,
        port: 8080,
    },
});

export default AxiosConfig;