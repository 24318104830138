import { useSelector } from "react-redux"

export const useSelectors = () => {
    const candidatSelected = useSelector((state: any) => state.candidatSelected)
    const entrepriseSelected = useSelector((state: any) => state.entrepriseSelected)
    const missionSelected = useSelector((state: any) => state.missionSelected)
    const location = useSelector((state: any) => state.router.location.pathname);
    const filter = useSelector((state: any) => state.filter.filter);

    return {
        candidatSelected,
        entrepriseSelected,
        missionSelected,
        location,
        filter
    }
}