import { ENTREPRISE_DESELECTED, ENTREPRISE_SELECTED } from "../../../../_const";
import { IEntrepriseSelected } from "../../../../interfaces/IEntrepriseSelected";

const initialState: IEntrepriseSelected = {
    _id: "",
    name_enterprise: "",
}

export const entrepriseSelectedReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case ENTREPRISE_SELECTED:
            return {
                ...state,
                _id: action.payload._id,
                name_enterprise: action.payload.name_enterprise
            }
        case ENTREPRISE_DESELECTED:
            return initialState
        default:
            return state;
    }
}