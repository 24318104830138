import { useDispatch, useSelector } from "react-redux"
import { isUserConnected } from "../_redux/reducers/Auth/actions"
import { useEffect } from "react"

export const useConnected = () => {
    const connected = isUserConnected()
    const dispatch = useDispatch()
    const user = useSelector((state: any) => state.auth.isAuthenticated);

    useEffect(() => {
        dispatch(connected)
    }, []);

    return {
        isUser: () => dispatch(isUserConnected()),
        user
    }
}