import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export const useBtnsDisplay = () => {
    const candidatSelected = useSelector((state: any) => state.candidatSelected)
    const entrepriseSelected = useSelector((state: any) => state.entrepriseSelected)
    const missionSelected = useSelector((state: any) => state.missionSelected)
    const isWorkTime = useSelector((state: any) => state.workTime)

    const [responsabilityTerms, setResponsabilityTerms] = useState(true);
    const [recrutement, setRecrutement] = useState(true);
    const [contractInterim, setContractInterim] = useState(true);
    const [disposition, setDisposition] = useState(true);
    const [mix, setMix] = useState(true);
    const [factureHours, setFactureHours] = useState(true);


    useEffect(() => {
        setRecrutement(candidatSelected._id === "");
        setResponsabilityTerms(candidatSelected._id === "")
        setContractInterim(candidatSelected._id === "" || entrepriseSelected._id === "" || missionSelected._id === "")
        setMix(candidatSelected._id === "" || entrepriseSelected._id === "" || missionSelected._id === "");
        setDisposition(candidatSelected._id === "" || entrepriseSelected._id === "" || missionSelected._id === "");
        setFactureHours(candidatSelected._id === "" || entrepriseSelected._id === "" || missionSelected._id === "" || isWorkTime.data === undefined);
    }, [candidatSelected, entrepriseSelected, missionSelected, isWorkTime]);

    return {
        recrutement,
        responsabilityTerms,
        contractInterim,
        disposition,
        mix,
        factureHours,
        candidatSelected,
        entrepriseSelected,
        missionSelected
    }
}