
import { ENTREPRISES_SUCCESS, ENTREPRISES_REQUEST, ENTREPRISES_FAILURE, ENTREPRISES_FILTERED, ENTREPRISE_UPDATED } from './actionTypes'
import { ISelectReduxState } from '../../../interfaces/ISelectReduxState';

const initialState: ISelectReduxState = {
    data: null,
    loading: false,
    error: null,
};

export const entrepriseReducer = (state = initialState, action: any): ISelectReduxState => {
    switch (action.type) {
        case ENTREPRISES_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case ENTREPRISES_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: null,
            };
        case ENTREPRISE_UPDATED:
            return {
                ...state,
                data: state.data.concat(action.payload),
                loading: false,
                error: null
            }
        case ENTREPRISES_FILTERED:
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: null,
            };
        case ENTREPRISES_FAILURE:
            return {
                ...state,
                data: null,
                loading: false,
                error: action.error,
            };
        default:
            return state;
    }
};