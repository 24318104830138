import React from "react";

export const useModalForm = (children: any) => {

    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: "20px",
        borderRadius: '16px',
        textAlign: "center"
    };

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const childrenWithProps = React.Children.map(children, (child) =>
        React.isValidElement(child)
            ? React.cloneElement(child as React.ReactElement<any>, { handleClose })
            : child
    );

    return {
        style,
        open,
        setOpen,
        handleClose,
        handleOpen,
        childrenWithProps
    }
}