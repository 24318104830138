import { useDispatch, useSelector } from "react-redux";
import { countAllEntrepriseDB } from "../_redux/reducers/CountListData/actions";
import { useEffect } from "react";

export const useCountEntreprise = () => {
    const count = useSelector((state: any) => state.count.data || 0);
    const filter = useSelector((state: any) => state.filter.filter);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!filter)
            dispatch(countAllEntrepriseDB());
        else
            dispatch(countAllEntrepriseDB(filter))
    }, [filter]);

    return {
        count
    }
}
