import { ENTREPRISE_DESELECTED, ENTREPRISE_SELECTED } from "../../../../_const"
import { IEntrepriseSelected } from "../../../../interfaces/IEntrepriseSelected";

export const dispatchSelectedEntreprise = (data: IEntrepriseSelected): any => (dispatch: any) => {
    if (!data) {
        return dispatch({
            type: ENTREPRISE_DESELECTED,
            payload: data
        });
    }
    return dispatch({
        type: ENTREPRISE_SELECTED,
        payload: data
    })
}

