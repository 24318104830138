import React, { useEffect, useState } from "react"
import "./SnackBar.scss"
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from "react-redux";
import { changeMessageSnack } from "../../_redux/reducers/Snack/actions";

export const SnackMessage = () => {
    const [open, setOpen] = useState<boolean>(false);
    const snackState = useSelector((state: any) => state.snack.message);
    const [message, setMessage] = useState<string>("")
    const dispatch = useDispatch();

    const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(changeMessageSnack(''));
        setOpen(false);
    };

    useEffect(() => {
        if (snackState === "") {
            setOpen(false)
        } else {
            setMessage(snackState)
            setOpen(true);
        }
    }, [snackState])

    const action = (
        <React.Fragment>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}>
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    return (
        <div className="snack-container">
            <Snackbar
                open={open}
                autoHideDuration={3000}
                onClose={handleClose}
                message={message}
                action={action}
            />
        </div>
    )
}