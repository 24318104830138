export const POST_METHOD = 'POST';
export const GET_METHOD = 'GET';
export const PUT_METHOD = 'PUT';
export const PATCH_METHOD = 'PATCH';
export const DELETE_METHOD = 'DELETE';

export const DEFAULT_SUCCESS_STATUS = 200;
export const DEFAULT_SUCCESS_MESSAGE = "Success";

export const DEFAULT_ERROR_STATUS = 500;
export const DEFAULT_ERROR_MESSAGE = "An error occured";

export const DEFAULT_WARNING_STATUS = 400;
export const DEFAULT_WARNING_MESSAGE = "Something went wrong";

export const UNAUTHORIZED_STATUS = 401;
export const UNAUTHORIZED_MESSAGE = "Not allowed";

export const CANDIDAT_TYPE = "candidat";
export const ENTERPRISE_TYPE = "enterprise";
export const MISSION_TYPE = "mission";

export const CANDIDATS_LABEL = "Interimaires";
export const ENTERPRISES_LABEL = "Entreprises";
export const MISSIONS_LABEL = "Missions";
export const FACTURES_LABEL = "Factures";

export const CANDIDAT_SELECTED = "CANDIDAT_SELECTED";
export const ENTREPRISE_SELECTED = "ENTREPRISE_SELECTED";
export const MISSION_SELECTED = "MISSION_SELECTED";

export const CANDIDAT_DESELECTED = "CANDIDAT_DESELECTED";
export const ENTREPRISE_DESELECTED = "ENTREPRISE_DESELECTED";
export const MISSION_DESELECTED = "MISSION_DESELECTED";

export const RECRUTMENT = "RECRUTMENT";
export const MIX = "MIX";
export const DISPOSITION = "DISPOSITION";
export const INTERIM_CONTRACT = "INTERIM_CONTRACT";
export const RESPONSABILITY_TERMS = "RESPONSABILITY_TERMS";
export const FACTURE_HOURS = "FACTURE_HOURS";

export const TEXTAREA_FORM_CONDITION = "Textarea";
export const NUMBER_FORM_CONDITION = "Number";
export const DATE_FORM_CONDITION = "Date";
export const SELECT_FORM_CONDITION = "Select";

export const LABEL_BTN_RECRUTMENT = "Recrutement";
export const LABEL_BTN_DISPOSITION = "Mise à Disposition";
export const LABEL_BTN_INTERIM = "Interim";
export const LABEL_BTN_RESPONSABILITÉ = "Responsabilité";
export const LABEL_BTN_FACTURE = "Facture";
export const LABEL_BTN_MIX = "Mix";

export const ALL_FIELDS_REQUIRED = "Tous les champs sont requis";
