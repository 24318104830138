import { IMissionCreate } from "../../../../interfaces/IMissionCreate";

const initialState: IMissionCreate = {
    motif: "",
    begin_date: "",
    end_date: "",
    description: "",
    qualification_prof_number: 0,
    salaire: 0,
    time_week: 0,
    risk: false,
    poste: "",
    location_mission: "",
    contract_date: "",
    level: 0,
    range: 0,
    brut_hour: 0,
    begin_time_morning: "",
    end_time_morning: "",
    begin_time_afternoon: "",
    end_time_afternoon: "",
    complet_time: 0,
    signature_date: "",
    pourcentage1: 0,
    fromHsTime1: 0,
    pourcentage2: 0,
    fromHsTime2: 0,
    // pourcentage3: 0,
    // fromHsTime3: 0
};

export const missionCreateReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case "motif":
            return {
                ...state,
                motif: action.payload.label
            };
        case "begin_date":
            return {
                ...state,
                begin_date: action.payload.label
            };
        case "end_date":
            return {
                ...state,
                end_date: action.payload.label
            };
        case "description":
            return {
                ...state,
                description: action.payload.label
            };
        case "qualification_prof_number":
            return {
                ...state,
                qualification_prof_number: action.payload.label
            };
        case "salaire":
            return {
                ...state,
                salaire: action.payload.label
            };
        case "time_week":
            return {
                ...state,
                time_week: action.payload.label
            };
        case "risk":
            return {
                ...state,
                risk: action.payload.label
            };
        case "poste":
            return {
                ...state,
                poste: action.payload.label
            };
        case "location_mission":
            return {
                ...state,
                location_mission: action.payload.label
            };
        case "contract_date":
            return {
                ...state,
                contract_date: action.payload.label
            };
        case "level":
            return {
                ...state,
                level: action.payload.label
            };
        case "range":
            return {
                ...state,
                range: action.payload.label
            };
        case "brut_hour":
            return {
                ...state,
                brut_hour: action.payload.label
            };
        case "begin_time_morning":
            return {
                ...state,
                begin_time_morning: action.payload.label
            };
        case "end_time_morning":
            return {
                ...state,
                end_time_morning: action.payload.label
            };
        case "begin_time_afternoon":
            return {
                ...state,
                begin_time_afternoon: action.payload.label
            };
        case "end_time_afternoon":
            return {
                ...state,
                end_time_afternoon: action.payload.label
            };
        case "complet_time":
            return {
                ...state,
                complet_time: action.payload.label
            };
        case "signature_date":
            return {
                ...state,
                signature_date: action.payload.label
            };
        case "fromHsTime1":
            return {
                ...state,
                fromHsTime1: action.payload.label
            };
        case "pourcentage1":
            return {
                ...state,
                pourcentage1: action.payload.label
            };
        case "fromHsTime2":
            return {
                ...state,
                fromHsTime2: action.payload.label
            };
        case "pourcentage2":
            return {
                ...state,
                pourcentage2: action.payload.label
            };
        // case "fromHsTime3":
        //     return {
        //         ...state,
        //         fromHsTime3: action.payload.label
        //     };
        // case "pourcentage3":
        //     return {
        //         ...state,
        //         pourcentage3: action.payload.label
        //     };
        default:
            return state;
    }
}