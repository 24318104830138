import React from "react"
import "./Hours.scss"
import { TimeForm } from "../../components/form/times-form/TimeForm"

export const Hours = () => {
    return (
        <div className="hours-container">
            <TimeForm />
        </div>
    )
}