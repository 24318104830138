import { createRequest } from "../lib/create-request"
import ServerResponse from '../interfaces/common/ServerResponse';

export const login = async (email: string, password: string) => {
    return createRequest().post<ServerResponse>('/auth/login', {
        body: {
            email,
            password
        }
    });
};


export const isConnected = async () => {
    return createRequest().get<ServerResponse>('/auth/isConnected')
}

export const logout = async () => {
    return createRequest().get<ServerResponse>('/auth/logout')
}