import React from "react";
import Button from '@mui/material/Button';
import { ButtonProps } from "./ButtonProps";
import { styled } from '@mui/material/styles';
import "./BasicButton.scss"

const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
    padding: 0,
    minHeight: "36px",
    borderRadius: "16px",
    '&:hover': {
    },
}));

export const BasicButton: React.FC<ButtonProps> = ({ label, onClick, variant, disabled, id }) => {
    return (
        <ColorButton id={id} className="button-display" variant={variant} onClick={onClick} disabled={disabled} label={""}>
            <h2>{label}</h2>
        </ColorButton>
    )
}