import { MISSION_DESELECTED, MISSION_SELECTED } from "../../../../_const";
import { IMissionSelected } from "../../../../interfaces/IMissionSelected";

const initialState: IMissionSelected = {
    _id: "",
    poste: ""
}

export const missionSelectedReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case MISSION_SELECTED:
            return {
                ...state,
                _id: action.payload._id,
                poste: action.payload.poste
            }
        case MISSION_DESELECTED:
            return initialState
        default:
            return state;
    }
}