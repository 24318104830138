import { ICandidatCreate } from "../../../../interfaces/ICandidatCreate";

const initialState: ICandidatCreate = {
    surname: "",
    name: "",
    nationality: "",
    date_birthday: "",
    profession: "",
    adress: "",
    cp: "",
    phone: "",
    card_number_citizen: 0,
    citizen_date_to: "",
    fiscal_identity_number: 0,
    niss: "",
};

export const candidatReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case "name":
            return {
                ...state,
                name: action.payload.label
            };
        case "surname":
            return {
                ...state,
                surname: action.payload.label
            };
        case "nationality":
            return {
                ...state,
                nationality: action.payload.label
            };
        case "date_birthday":
            return {
                ...state,
                date_birthday: action.payload.label
            };
        case "card_number_citizen":
            return {
                ...state,
                card_number_citizen: action.payload.label
            };
        case "citizen_date_to":
            return {
                ...state,
                citizen_date_to: action.payload.label
            };
        case "fiscal_identity_number":
            return {
                ...state,
                fiscal_identity_number: action.payload.label
            };
        case "profession":
            return {
                ...state,
                profession: action.payload.label
            };
        case "adress":
            return {
                ...state,
                adress: action.payload.label
            };
        case "cp":
            return {
                ...state,
                cp: action.payload.label
            };
        case "phone":
            return {
                ...state,
                phone: action.payload.label
            };
        case "niss":
            return {
                ...state,
                niss: action.payload.label
            };
        default:
            return state;
    }
}