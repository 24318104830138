import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMissionsByIdEnterprise, searchMission } from "../_redux/reducers/Mission/actions";
import { changeFilter } from "../_redux/reducers/filter/actions";
import { restartWeekTime } from "../_redux/reducers/WorkTime/actions";

export const useMissions = () => {
    const dispatch = useDispatch();
    const entreprise = useSelector((state: any) => state.entrepriseSelected);
    const missionsEntreprise = useSelector((state: any) => state.missions);
    const [page, setPage] = useState<any>(0);
    const [missionsArray, setMissionsArray] = useState<any>([]);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [selectionModel, setSelectionModel] = useState<string[]>([]);
    const filter = useSelector((state: any) => state.filter.filter)
    const missionSelected = useSelector((state: any) => state.missionSelected._id)

    useEffect(() => {
        if (!filter && entreprise && entreprise._id) {
            dispatch(getMissionsByIdEnterprise(entreprise._id, page))
        } else if (filter && entreprise && entreprise._id) {
            dispatch(searchMission(entreprise._id, filter, page))
        }
    }, [page, entreprise])

    useEffect(() => {
        setPage(0)
    }, [filter])

    useEffect(() => {
        if (missionsEntreprise && missionsEntreprise.data)
            setMissionsArray(missionsEntreprise.data.map((entry: any) => {
                return {
                    ...entry,
                    id: entry._id
                }
            }));
    }, [missionsEntreprise]);

    useEffect(() => {
        setSelectionModel(missionSelected)
        dispatch(restartWeekTime())
    }, [missionSelected])

    const handleChangePage = (event: any, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
        const isChecked = event.target.checked;
        setSelectionModel(isChecked ? [id] : []);
    };

    const searchMissionsByEntreprises = (filter: string, page: number) => {
        dispatch(changeFilter(filter))
        if (!filter) {
            dispatch(getMissionsByIdEnterprise(entreprise._id, page));
        } else {
            dispatch(searchMission(entreprise._id, filter, page));
        }
    };

    return {
        searchMissionsByEntreprises,
        missionsEntreprise,
        rowsPerPage,
        page,
        handleChangePage,
        handleChangeRowsPerPage,
        missionsArray,
        handleRadioChange,
        selectionModel
    }
}