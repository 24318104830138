import { useDispatch, useSelector } from "react-redux";
import { countAllMissionsDB } from "../_redux/reducers/CountListData/actions";
import { useEffect } from "react";

export const useCountMissions = () => {
    let count = useSelector((state: any) => state.count.data)
    const entrepriseId = useSelector((state: any) => state.entrepriseSelected._id);
    const filter = useSelector((state: any) => state.filter.filter);
    const dispatch = useDispatch();

    useEffect(() => {
        if (entrepriseId && filter) {
            dispatch(countAllMissionsDB(entrepriseId, filter));
        }
        else if (entrepriseId && !filter) {
            dispatch(countAllMissionsDB(entrepriseId));

        }
    }, [entrepriseId, filter]);

    return {
        count
    }
}