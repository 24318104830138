import { IFormFields } from "../../../../interfaces/IFormFields"

export const changeDataEnterprise = (data: IFormFields): any => (dispatch: any) => {
    try {
        return dispatch({
            type: data.fieldId,
            payload: data
        })
    } catch (error) {
        console.log("error dispatch action changeDataSet")
    }
}