import React, { useEffect, useState } from "react"
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import { useMissionForm } from "../../../hooks/useMissionForm"
import { BasicButton } from "../../button/Button"
import { MenuItem } from "@mui/material"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { DemoContainer } from "@mui/x-date-pickers/internals/demo"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { DATE_FORM_CONDITION, NUMBER_FORM_CONDITION, SELECT_FORM_CONDITION, TEXTAREA_FORM_CONDITION } from "../../../_const"
import "../../form/FormIndex.scss"

export const MissionForm: React.FC<any> = ({ handleClose }) => {
    const mission = useMissionForm();
    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
        >
            <LocalizationProvider dateAdapter={AdapterDayjs} >
                <DemoContainer components={['DatePicker']}>
                    <div className="fields-container">
                        {mission.fields.map((field: any) => {
                            switch (field.type) {
                                case SELECT_FORM_CONDITION:
                                    if (field.id === "begin_time_morning" || field.id === "end_time_morning" || field.id === "begin_time_afternoon" || field.id === "end_time_afternoon") {
                                        return (
                                            <TextField
                                                sx={mission.displayErrorHoursWork &&
                                                {
                                                    border: '1px solid red',
                                                    borderRadius: '4px',
                                                }}
                                                select
                                                key={field.id}
                                                id={field.id}
                                                label={field.label}
                                                onChange={(e) => {
                                                    mission.setFieldData({ fieldId: field.id, label: e.target.value })
                                                }}
                                            >
                                                {field && field.options && field.options.map((option: any) => (
                                                    <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                                ))}

                                            </TextField>
                                        );
                                    } else {
                                        return (
                                            <TextField
                                                select
                                                key={field.id}
                                                id={field.id}
                                                label={field.label}
                                                onChange={(e) => {
                                                    mission.setFieldData({ fieldId: field.id, label: e.target.value })
                                                }}
                                            >
                                                {field && field.options && field.options.map((option: any) => (
                                                    <MenuItem key={option.value} value={option.value}>{option.label}</MenuItem>
                                                ))}

                                            </TextField>
                                        );
                                    }
                                case DATE_FORM_CONDITION:
                                    if (field.id === "begin_date" || field.id === "end_date") {
                                        return (
                                            <DatePicker
                                                sx={mission.displayError &&
                                                {
                                                    border: '1px solid red',
                                                    borderRadius: '4px',
                                                }}
                                                key={field.id}
                                                format="DD-MM-YYYY"
                                                label={field.label}
                                                onChange={(e: any) => {
                                                    let date = `${e.$D}/${e.$M + 1}/${e.$y}`
                                                    mission.setFieldData({ fieldId: field.id, label: date })
                                                }}
                                            />
                                        );
                                    } else {
                                        return (
                                            <DatePicker
                                                key={field.id}
                                                format="DD-MM-YYYY"
                                                label={field.label}
                                                onChange={(e: any) => {
                                                    let date = `${e.$D}/${e.$M + 1}/${e.$y}`
                                                    mission.setFieldData({ fieldId: field.id, label: date })
                                                }}
                                            />
                                        );
                                    }
                                case NUMBER_FORM_CONDITION:
                                    return (
                                        <TextField
                                            key={field.id}
                                            id={field.id}
                                            label={field.label}
                                            type="number"
                                            onChange={(e) => {
                                                mission.setFieldData({ fieldId: field.id, label: parseInt(e.target.value) })
                                            }}
                                        />
                                    );
                                case TEXTAREA_FORM_CONDITION:
                                    return (
                                        <div className="textarea-container">
                                            <textarea
                                                className="textarea-form"
                                                key={field.id}
                                                id={field.id}
                                                placeholder={field.label}
                                                onChange={(e) => {
                                                    mission.setFieldData({ fieldId: field.id, label: e.target.value })
                                                }}
                                            />
                                        </div>
                                    );

                                default:
                                    return (
                                        < TextField
                                            key={field.id}
                                            id={field.id}
                                            label={field.label}
                                            onChange={(e) => {
                                                mission.setFieldData({ fieldId: field.id, label: e.target.value })
                                            }
                                            }
                                        />
                                    );
                            }
                        })}
                    </div>
                </DemoContainer>
            </LocalizationProvider>
            <BasicButton id="save" variant="contained" label="Sauvegarder" onClick={() => {
                mission.createMissionDispatch()
                handleClose()
            }
            } />
        </Box>
    )
}