import { FILTER, FILTER_EMPTY } from "./actionTypes";

const initialState: {} = {
    filter: ""
}

export const filterReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case FILTER:
            return {
                ...state,
                filter: action.payload,
            }
        case FILTER_EMPTY:
            return initialState

        default:
            return state;
    }

}