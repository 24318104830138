import { IWeekTime } from "../interfaces/IWeekTime";
import ServerResponse from "../interfaces/common/ServerResponse";
import { createRequest } from "../lib/create-request";

export const createWorksTimes = async (data: IWeekTime) => {
    return createRequest().post<ServerResponse>('/times/createWorksTimes', {
        body: {
            data
        }
    });
};
