import { useDispatch, useSelector } from "react-redux"
import { entrepriseFields } from "../assets/Data/data.js";
import { IFormFields } from "../interfaces/IFormFields";
import { changeDataEnterprise } from "../_redux/reducers/Form/entreprise/actions";
import { createEnterprise } from "../_redux/reducers/Entreprise/actions";
import { checkNonEmptyStrings } from "../Utils";
import { changeMessageSnack } from "../_redux/reducers/Snack/actions";
import { ALL_FIELDS_REQUIRED } from "../_const.js";

export const useEntrepriseForm = () => {
    const fields = entrepriseFields;
    const entrepriseFieldsRedux = useSelector((state: any) => state.entreprise)
    const dispatch = useDispatch()

    const setFieldData = (data: IFormFields) => {
        dispatch(changeDataEnterprise(data))
    }

    const createEnterpriseDispatch = () => {
        const isValid = checkNonEmptyStrings(entrepriseFieldsRedux)
        if (!isValid) {
            dispatch(changeMessageSnack("Tous les champs sont requis"))
            return;
        }
        dispatch(createEnterprise(entrepriseFieldsRedux))
    }

    return {
        fields,
        setFieldData,
        createEnterpriseDispatch
    }
}

