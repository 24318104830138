
import { COUNT_FAILURE, COUNT_SUCCESS } from './actionTypes'
import { ISelectReduxState } from '../../../interfaces/ISelectReduxState';
import { ENTREPRISES_REQUEST } from '../Entreprise/actionTypes';
import { MISSIONS_REQUEST } from '../Mission/actionTypes';
import { CANDIDATS_LOADING } from '../Candidat/actionTypes';


const initialState: ISelectReduxState = {
    data: null,
    loading: false,
    error: null,
};

export const countListReducer = (state = initialState, action: any): ISelectReduxState => {
    switch (action.type) {
        case CANDIDATS_LOADING:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case ENTREPRISES_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case MISSIONS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case COUNT_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: null,
            };

        case COUNT_FAILURE:
            return {
                ...state,
                data: null,
                loading: false,
                error: action.error,
            };
        default:
            return state;
    }
};