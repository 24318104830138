import { useDispatch, useSelector } from "react-redux"
import { createDataTimeWork } from "../_redux/reducers/WorkTime/actions"
import { useEffect, useState } from "react";
import { IWeekTime } from "../interfaces/IWeekTime";
import { changeMessageSnack } from "../_redux/reducers/Snack/actions";

export const useTimeForm = () => {
    const dispatch = useDispatch();
    const missionId = useSelector((state: any) => state.missionSelected._id);
    const entrepriseId = useSelector((state: any) => state.entrepriseSelected._id);
    const candidatId = useSelector((state: any) => state.candidatSelected._id);
    const [blocksData, setBlocksData] = useState<any>([
        { title: "semaine 1", begin_date: "", end_date: "", hours_count: 0 },
    ]);
    const [displayErrorPicker, setDisplayErrorPicker] = useState<any>([false])
    const [indexOfErrors, setIndexOfErrors] = useState([]);

    useEffect(() => {
        const newIndexOfErrors = displayErrorPicker.reduce((acc: any, el: any, index: any) => {
            if (el) {
                acc.push(index);
            }
            return acc;
        }, []);

        setIndexOfErrors(newIndexOfErrors);
    }, [displayErrorPicker]);

    const addWeekBlock = () => {
        setBlocksData((prevBlocksData: any) => [
            ...prevBlocksData,
            {
                title: `semaine ${prevBlocksData.length + 1}`,
                begin_date: "",
                end_date: "",
                hours_count: "",
            },
        ]);
        setDisplayErrorPicker((prevStateError: any) => [
            ...prevStateError, false
        ])
    };

    const removeWeekBlock = () => {
        if (blocksData.length === 1) {
            return
        } else {
            setBlocksData((prevBlocksData: any) => {
                const updatedBlocksData = prevBlocksData.slice(0, -1);
                return updatedBlocksData;
            });
            setDisplayErrorPicker((prevStateError: any) => {
                const updatedStatesError = prevStateError.slice(0, -1);
                return updatedStatesError;
            })
        }
    }

    const updateBlockData = (blockIndex: number, field: any, value: any) => {
        setBlocksData((prevBlocksData: any) => {
            const blocksDataUpdated = [...prevBlocksData];
            blocksDataUpdated[blockIndex] = { ...blocksDataUpdated[blockIndex], [field]: value };
            const startDate = new Date(blocksDataUpdated[blockIndex].begin_date).getTime();
            const endDate = new Date(blocksDataUpdated[blockIndex].end_date).getTime();
            if (endDate < startDate) {
                setDisplayErrorPicker((prevState: any) => {
                    const states = [...prevState];
                    states[blockIndex] = true;
                    return states;
                });
            } else if (endDate > startDate) {
                setDisplayErrorPicker((prevState: any) => {
                    const states = [...prevState];
                    states[blockIndex] = false;
                    return states;
                });
            } else if (endDate === startDate) {
                setDisplayErrorPicker((prevState: any) => {
                    const states = [...prevState];
                    states[blockIndex] = false;
                    return states;
                });
            }
            return blocksDataUpdated;
        });
    };

    const createDataTime = () => {
        const missingFieldsError = blocksData.some((data: IWeekTime) => !data.begin_date || !data.end_date || !data.hours_count);
        if (missingFieldsError)
            return dispatch(changeMessageSnack("Veuillez saisir tous les champs"));
        let formatDateData = blocksData.map((el: any) => {
            el.begin_date = new Date(el.begin_date).toISOString();
            el.end_date = new Date(el.end_date).toISOString();
            el.mission_id = missionId;
            el.enterprise_id = entrepriseId;
            el.candidats_id = candidatId;
            return el;
        })
        dispatch(createDataTimeWork(formatDateData))
        setBlocksData([
            { title: "semaine 1", begin_date: "", end_date: "", hours_count: " " },
        ])
    }

    return {
        createDataTime,
        addWeekBlock,
        updateBlockData,
        blocksData,
        removeWeekBlock,
        indexOfErrors,
    }
}