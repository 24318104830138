import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import React from "react"
import { BasicButton } from "../../button/Button"
import { useCandidatForm } from "../../../hooks/useCandidatForm"
import "../../../index.scss"
import "./CandidatForm.scss"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { DemoContainer } from "@mui/x-date-pickers/internals/demo"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { DATE_FORM_CONDITION, NUMBER_FORM_CONDITION } from "../../../_const"

export const CandidatForm: React.FC<any> = ({ handleClose }) => {
    const { fields, createCandidatDispatch, setFieldData } = useCandidatForm()
    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
        >
            <LocalizationProvider dateAdapter={AdapterDayjs} >
                <DemoContainer components={['DatePicker']}>
                    <div className="fields-container">
                        {fields.map((field: any) => {
                            switch (field.type) {
                                case DATE_FORM_CONDITION:
                                    return (
                                        <DatePicker
                                            key={field.id}
                                            format="DD-MM-YYYY"
                                            label={field.label}
                                            onChange={(e: any) => {
                                                let date = `${e.$D}/${e.$M + 1}/${e.$y}`
                                                setFieldData({ fieldId: field.id, label: date })
                                            }}
                                        />
                                    );
                                case NUMBER_FORM_CONDITION:
                                    return (
                                        <TextField
                                            key={field.id}
                                            id={field.id}
                                            label={field.label}
                                            type="number"
                                            onChange={(e) => {
                                                setFieldData({ fieldId: field.id, label: e.target.value })
                                            }}
                                        />
                                    );
                                default:
                                    return (
                                        < TextField
                                            key={field.id}
                                            id={field.id}
                                            label={field.label}
                                            onChange={(e) => {
                                                setFieldData({ fieldId: field.id, label: e.target.value })
                                            }
                                            }
                                        />
                                    );
                            }
                        })}
                    </div>
                </DemoContainer>
            </LocalizationProvider>
            <BasicButton id='save' variant="contained" label="Sauvegarder" onClick={() => {
                createCandidatDispatch()
                handleClose()
            }
            } />
        </Box >
    )
}