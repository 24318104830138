import { FILTER, FILTER_EMPTY } from "./actionTypes";

export const changeFilter = (data: any): any => (dispatch: any) => {
    try {
        if (!data) {
            return dispatch({
                type: FILTER_EMPTY,
                payload: data
            });
        }
        return dispatch({
            type: FILTER,
            payload: data
        })
    } catch (error) {
        console.log("error : ", error);
    }
}