import { useDispatch, useSelector } from "react-redux"
import { candidatFields } from "../assets/Data/data.js";
import { changeDataCandidat } from "../_redux/reducers/Form/candidat/actions";
import { IFormFields } from "../interfaces/IFormFields";
import { createCandidat } from "../_redux/reducers/Candidat/actions";
import { checkNonEmptyStrings } from "../Utils";
import { changeMessageSnack } from "../_redux/reducers/Snack/actions";
import { ALL_FIELDS_REQUIRED } from "../_const.js";

export const useCandidatForm = () => {
    const fields = candidatFields;
    const candidatFieldsRedux = useSelector((state: any) => state.candidat)
    const dispatch = useDispatch()

    const setFieldData = (data: IFormFields) => {
        dispatch(changeDataCandidat(data))
    }

    const createCandidatDispatch = () => {
        const isValid = checkNonEmptyStrings(candidatFieldsRedux)
        if (!isValid) {
            dispatch(changeMessageSnack("Tous les champs sont requis"))
            return;
        }
        dispatch(createCandidat(candidatFieldsRedux))
    }

    return {
        fields,
        candidatFieldsRedux,
        setFieldData,
        createCandidatDispatch
    }
}

