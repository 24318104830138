import { authReducer } from "../_redux/reducers/Auth/authReducer"
import { candidatsReducer } from './reducers/Candidat/candidatsReducer';
import { entrepriseReducer } from './reducers/Entreprise/enterprisesReducer';
import { missionsReducer } from './reducers/Mission/missionsReducer';
import { candidatReducer } from './reducers/Form/candidat/candidatReducer'
import { enterpriseReducer } from './reducers/Form/entreprise/enterpriseReducer';
import { missionCreateReducer } from './reducers/Form/mission/missionCreateReducer';
import { candidatSelectedReducer } from './reducers/Select/candidat/candidatSelectedReducer';
import { entrepriseSelectedReducer } from './reducers/Select/entreprise/enterpriseSelectedReducer';
import { missionSelectedReducer } from './reducers/Select/mission/missionSelectedReducer';
import { weeksDataWorkReducer } from './reducers/WorkTime/weeksDataWorkReducer';

import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { createReduxHistoryContext } from 'redux-first-history';
import { createBrowserHistory } from 'history'; // history@5
import { filterReducer } from "./reducers/filter/filterReducer";
import { countListReducer } from "./reducers/CountListData/countListReducer";
import { snackReducer } from "./reducers/Snack/snackReducer";
import { documentReducer } from "./reducers/Document/documentReducer";

const {
    createReduxHistory,
    routerMiddleware,
    routerReducer
} = createReduxHistoryContext({
    history: createBrowserHistory(),
    savePreviousLocations: 50, // last 50 history entries
    // other options if needed 
});

const rootReducer = combineReducers({
    auth: authReducer,
    candidats: candidatsReducer,
    entreprises: entrepriseReducer,
    missions: missionsReducer,
    candidat: candidatReducer,
    entreprise: enterpriseReducer,
    mission: missionCreateReducer,
    candidatSelected: candidatSelectedReducer,
    entrepriseSelected: entrepriseSelectedReducer,
    missionSelected: missionSelectedReducer,
    workTime: weeksDataWorkReducer,
    document: documentReducer,
    filter: filterReducer,
    count: countListReducer,
    snack: snackReducer,
    router: routerReducer,
});

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleWare) =>
        getDefaultMiddleWare()
            .concat(routerMiddleware),
});

export const history = createReduxHistory(store);