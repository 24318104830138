import React from "react";
import "./ModalForm.scss";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { useModalForm } from "../../hooks/useModalForm";
import { ModalFormProps } from "./ModalFormProps";

export const ModalForm: React.FC<ModalFormProps> = ({ children }) => {
    const { style, handleOpen, open, handleClose, childrenWithProps } = useModalForm(children)

    return (
        <div className="modal-form-container">
            <Button onClick={handleOpen}>
                <i className="fa-solid fa-plus"></i>
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {childrenWithProps}
                </Box>
            </Modal>
        </div >
    )
}