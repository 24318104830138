import { useDispatch } from "react-redux";
import { logout } from "../_redux/reducers/Auth/actions";

export const useLogout = () => {
    const dispatch = useDispatch();

    const handleLogout = () => {
        dispatch(logout());
    };

    return {
        handleLogout,
    }
}