import { SNACK_MESSAGE } from "./actionTypes";

const initialState: {} = {
    message: "",
}

export const snackReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case SNACK_MESSAGE:
            return {
                ...state,
                message: action.payload,
            }
        default:
            return state;
    }

}