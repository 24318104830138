import React from "react"
import { BasicButton } from "../button/Button"
import { useBtnsDisplay } from "../../hooks/useBtnsDisplay"
import { useGenerateDoc } from "../../hooks/useGenerateDoc"
import { DISPOSITION, FACTURE_HOURS, INTERIM_CONTRACT, LABEL_BTN_DISPOSITION, LABEL_BTN_FACTURE, LABEL_BTN_INTERIM, LABEL_BTN_MIX, LABEL_BTN_RECRUTMENT, LABEL_BTN_RESPONSABILITÉ, MIX, RECRUTMENT, RESPONSABILITY_TERMS } from "../../_const"
import "./BtnDocs.scss"

export const BtnsDoc: React.FC<any> = () => {
    const { recrutement, responsabilityTerms, contractInterim, disposition, mix, factureHours } = useBtnsDisplay()
    const { generateDoc } = useGenerateDoc()

    return (
        <div className="btns-container">
            <BasicButton
                label={LABEL_BTN_RECRUTMENT}
                onClick={() => generateDoc(RECRUTMENT)}
                variant="contained"
                disabled={recrutement}
            />
            <BasicButton
                label={LABEL_BTN_DISPOSITION}
                onClick={() => generateDoc(DISPOSITION)}
                variant='contained'
                disabled={disposition}
            />
            <BasicButton
                label={LABEL_BTN_INTERIM}
                onClick={() => generateDoc(INTERIM_CONTRACT)}
                variant='contained'
                disabled={contractInterim}
            />
            <BasicButton
                label={LABEL_BTN_RESPONSABILITÉ}
                onClick={() => generateDoc(RESPONSABILITY_TERMS)}
                variant='contained'
                disabled={responsabilityTerms}
            />
            <BasicButton
                label={LABEL_BTN_MIX}
                onClick={() => generateDoc(MIX)}
                variant='contained'
                disabled={mix}
            />
            <BasicButton
                label={LABEL_BTN_FACTURE}
                onClick={() => generateDoc(FACTURE_HOURS)}
                variant='contained'
                disabled={factureHours}
            />
        </div>
    )
}

