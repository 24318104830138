import { IWeekTime } from "../../../interfaces/IWeekTime";
import { WORK_TIME_LOADING, WORK_TIME_SUCCESS } from "./actionTypes";

const initialState: IWeekTime = {
    begin_date: "",
    end_date: "",
    hours_count: 0,
};

export const weeksDataWorkReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case WORK_TIME_LOADING:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case WORK_TIME_SUCCESS:
            return {
                data: action.payload
            }
        case "WORK_TIME_RESTART":
            return {
                initialState
            }
        default:
            return state;
    }
}