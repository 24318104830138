import React from 'react';
import { Outlet } from 'react-router-dom';
import { Bottom } from './Bottom/Bottom';
import { ListLeft } from './ListLeft/ListLeft';
import { SnackMessage } from '../components/snackBar/SnackBar';

const Layout: React.FC = () => {

    return (
        <div>
            <ListLeft />
            <main>
                <Outlet />
            </main>
            <SnackMessage />
            <Bottom />
        </div>
    );
};

export default Layout;