import { useDispatch, useSelector } from "react-redux";
import { countCandidatsDB } from "../_redux/reducers/CountListData/actions";
import { useEffect } from "react";

export const useCountCandidats = () => {
    const count = useSelector((state: any) => state.count.data);
    const filter = useSelector((state: any) => state.filter.filter);
    const dispatch = useDispatch();

    useEffect(() => {
        if (!filter)
            dispatch(countCandidatsDB());
        else
            dispatch(countCandidatsDB(filter))
    }, [filter]);

    return {
        count
    }
}