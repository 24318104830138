import React from "react";
import { useSelectors } from "../../hooks/useSelectors"
import { BtnsDoc } from "../btnsDoc/BtnDocs";
import "./Preview.scss"
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import BusinessIcon from '@mui/icons-material/Business';
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert';
import { useSelector } from "react-redux";
import ArticleIcon from '@mui/icons-material/Article';

export const Preview = () => {
    const { candidatSelected, entrepriseSelected, missionSelected } = useSelectors()
    const hoursAdded = useSelector((state: any) => state.workTime)

    return (
        <div className="preview-btns-container">
            <div className="preview-container">
                {candidatSelected && <p><SupervisedUserCircleIcon /><span className="ellipsis">{candidatSelected.name} {candidatSelected.surname}</span></p>}
                {entrepriseSelected && <p><BusinessIcon /><span className="ellipsis">{entrepriseSelected.name_enterprise}</span></p>}
                {missionSelected && <p><CrisisAlertIcon /><span className="ellipsis">{missionSelected && missionSelected.poste}</span></p>}
                {hoursAdded && <p>< ArticleIcon /> <span className="ellipsis">{hoursAdded && hoursAdded && hoursAdded.data && hoursAdded.data.length + " weeks"} </span></p>}
            </div>
            <BtnsDoc />
        </div >
    )
}


