import { MISSION_DESELECTED, MISSION_SELECTED } from "../../../../_const"
import { IMissionSelected } from "../../../../interfaces/IMissionSelected"

export const dispatchSelectedMission = (data?: IMissionSelected): any => (dispatch: any) => {
    try {
        if (!data) {
            return dispatch({
                type: MISSION_DESELECTED,
                payload: data
            })
        }
        return dispatch({
            type: MISSION_SELECTED,
            payload: data
        })
    } catch (error) {

    }
}