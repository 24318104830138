import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

export const useLeftMenu = () => {
    const navigate = useNavigate()
    const [value, setValue] = useState<number>(0)
    const [disableMission, setDisableMission] = useState<boolean>(true)
    const [disableHours, setDisableHours] = useState<boolean>(true)
    const entrepriseSelected = useSelector((state: any) => state.entrepriseSelected)
    const missionSelected = useSelector((state: any) => state.missionSelected)
    const candidatSelected = useSelector((state: any) => state.candidatSelected)
    const locationPathname = useSelector((state: any) => state.router.location)
    // const [isDisplay, setIsDisplay] = useState<string>("left-list-container")
    const [isDisplay, setIsDisplay] = useState<string>("hidden")

    useEffect(() => {
        if (locationPathname.pathname)
            if (locationPathname.pathname === "/login") {
                setIsDisplay("hidden")
            } else setIsDisplay("left-list-container")
    }, [locationPathname.pathname])

    useEffect(() => {
        if (entrepriseSelected._id)
            setDisableMission(false)
    }, [entrepriseSelected])

    useEffect(() => {
        if (missionSelected._id && candidatSelected._id) setDisableHours(false)
        else setDisableHours(true)
    }, [missionSelected, candidatSelected])

    useEffect(() => {
        if (locationPathname.pathname)
            if (locationPathname.pathname === "/candidats") {
                setValue(0)
            } else if (locationPathname.pathname === "/entreprises") {
                setValue(1)
            } else if (locationPathname.pathname === "/missions") {
                if (!entrepriseSelected._id) {
                    setValue(1)
                    navigate("/candidats")
                } else
                    setValue(2)
            } else if (locationPathname.pathname === "/hours-form") {
                if (!entrepriseSelected._id || !missionSelected._id) {
                    setValue(1)
                    navigate("/candidats")
                } else
                    setValue(3)
            }
    }, [locationPathname.pathname])

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
        switch (newValue) {
            case 0:
                navigate('/candidats');
                break
            case 1:
                navigate('/entreprises')
                break
            case 2:
                navigate('/missions')
                break
            case 3:
                navigate('/hours-form')
                break
        }
    };

    const a11yProps = (index: number) => {
        return {
            id: `vertical-tab-${index}`,
            'aria-controls': `vertical-tabpanel-${index}`,
        };
    }

    return {
        a11yProps,
        handleChange,
        value,
        disableMission,
        disableHours,
        isDisplay
    }
}