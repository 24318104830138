import { DEFAULT_SUCCESS_STATUS } from "../../../_const"
import { IWeekTime } from "../../../interfaces/IWeekTime"
import ServerResponse from "../../../interfaces/common/ServerResponse"
import { createWorksTimes } from "../../../manager/workTime"
import { changeMessageSnack } from "../Snack/actions"
import { WORK_TIME_ERROR, WORK_TIME_SUCCESS } from "./actionTypes"

export const changeDataTimeWorkByOrder = (data: IWeekTime): any => (dispatch: any) => {
    try {
        return dispatch({
            type: WORK_TIME_SUCCESS,
            payload: data
        })
    } catch (error) {
        console.log("error dispatch action changeDataTimeWorkByOrder")
        return dispatch({
            type: WORK_TIME_ERROR,
            payload: {
                message: "perdu"
            }
        })
    }
}

export const restartWeekTime = (data?: IWeekTime): any => (dispatch: any) => {
    try {
        return dispatch({
            type: "WORK_TIME_RESTART",
            payload: data
        })
    } catch (error) {

    }
}

export const createDataTimeWork = (data: any): any => async (dispatch: any) => {
    try {
        const response: ServerResponse = await createWorksTimes(data);
        dispatch(changeMessageSnack(response.message))
        if (response.status !== DEFAULT_SUCCESS_STATUS)
            return dispatch({
                type: WORK_TIME_ERROR,
                payload: response
            })
        return dispatch({
            type: WORK_TIME_SUCCESS,
            payload: data,
        })
    } catch (error) {
        console.log("error dispatch action createDataTimeWork");
        return dispatch({
            type: WORK_TIME_ERROR,
            payload: {
                message: "Une erreur est survenue"
            }
        })
    }
}