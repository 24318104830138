import React from "react"
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimeWeekForm } from "./TimeWeekForm";
import { BasicButton } from "../../button/Button";
import { useTimeForm } from "../../../hooks/useTimeForm";
import './TimeForm.scss'

export const TimeForm = () => {
    const { blocksData, updateBlockData, addWeekBlock, createDataTime, removeWeekBlock, indexOfErrors } = useTimeForm()
    return (
        <div className="time-week-form-container">
            <LocalizationProvider dateAdapter={AdapterDayjs} >
                <DemoContainer components={['DatePicker']}>
                    <div className="cards-week-container">
                        {blocksData.slice(0, 5).map((block: any, index: number) => (
                            <div key={index}>
                                <TimeWeekForm
                                    week={block}
                                    blockIndex={index}
                                    updateBlockData={updateBlockData}
                                    removeWeekBlock={removeWeekBlock}
                                    indexOfErrors={indexOfErrors}
                                />
                            </div>
                        ))}
                        <div className="shadow-card-week">
                            <button onClick={() => addWeekBlock()}><i className="fa-solid fa-plus"></i></button>
                        </div>
                    </div>

                </DemoContainer>
            </LocalizationProvider>
            <div className="valid-remove-container">
                <BasicButton id='valid-week' variant="contained" label="Valider" onClick={() => {
                    createDataTime();
                }} />
                <BasicButton id='remove-week' variant="contained" label="Supprimer semaine" onClick={() => {
                    removeWeekBlock();
                }} />
            </div>
        </div>
    )
}