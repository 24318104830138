import { DEFAULT_ERROR_MESSAGE, DEFAULT_SUCCESS_STATUS } from "../../../_const";
import { getCountAllCandidats } from "../../../manager/candidat";
import { COUNT_FAILURE, COUNT_SUCCESS } from "./actionTypes";
import { getCountAllEntreprises } from "../../../manager/entreprise";
import { getCountAllMissions } from "../../../manager/mission";
import ServerResponse from "../../../interfaces/common/ServerResponse";

export const countCandidatsDB = (filter?: string): any => async (dispatch: any) => {
    try {
        let response: ServerResponse = await getCountAllCandidats(filter);
        if (response && response.status !== DEFAULT_SUCCESS_STATUS) {
            return dispatch({
                type: COUNT_FAILURE,
                payload: response
            });
        }

        return dispatch({
            type: COUNT_SUCCESS,
            payload: response?.data
        })
    } catch (error) {
        return dispatch({
            type: COUNT_FAILURE,
            payload: {
                message: DEFAULT_ERROR_MESSAGE
            }
        });
    }
}
export const countAllEntrepriseDB = (filter?: string): any => async (dispatch: any) => {
    try {
        const response: ServerResponse = await getCountAllEntreprises(filter);
        if (response.status !== DEFAULT_SUCCESS_STATUS) {
            return dispatch({
                type: COUNT_FAILURE,
                payload: response
            });
        }
        return dispatch({
            type: COUNT_SUCCESS,
            payload: response.data
        })
    } catch (error) {
        return dispatch({
            type: COUNT_FAILURE,
            payload: {
                message: DEFAULT_ERROR_MESSAGE
            }
        });
    }
}

export const countAllMissionsDB = (entrepriseId: string, filter?: string): any => async (dispatch: any) => {
    try {
        const response: ServerResponse = await getCountAllMissions(entrepriseId, filter);
        if (response.status !== DEFAULT_SUCCESS_STATUS) {
            return dispatch({
                type: COUNT_FAILURE,
                payload: response
            });
        }
        return dispatch({
            type: COUNT_SUCCESS,
            payload: response.data
        })
    } catch (error) {
        return dispatch({
            type: COUNT_FAILURE,
            payload: {
                message: DEFAULT_ERROR_MESSAGE
            }
        });
    }
}

