import { ICandidatCreate } from "../interfaces/ICandidatCreate";
import ServerResponse from "../interfaces/common/ServerResponse";
import { createRequest } from "../lib/create-request";

export const getCandidatsIdAndLabel = async (page: any) => {
    return createRequest().get<ServerResponse>(`/candidat/selectsCandidats?page=${page}`, {});
}

export const getCountAllCandidats = async (filter?: string) => {
    return createRequest().get<ServerResponse>('/candidat/count/' + filter, {});
}

export const createCandidatManager = async (data: ICandidatCreate) => {
    return createRequest().post<ServerResponse>('/candidat/createCandidat', {
        body: data
    })
}

export const getCandidatsFiltered = async (filter: string, page: any) => {
    const result = createRequest().get<ServerResponse>(`/candidat/candidat/${filter}?page=${encodeURIComponent(page)}`);
    return result
}