import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../_redux/reducers/Auth/actions";
import { useNavigate } from "react-router-dom";
import { ILoginData } from "../interfaces/ILoginData"

export const useLogin = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [email, setEmail] = useState<string | undefined>(undefined);
    const [password, setPassword] = useState<string>('');
    const [message, setMessage] = useState<string>('');
    const auth = useSelector((state: any) => state.auth);

    const handleLogin = (data: ILoginData) => {
        if (!data.email || !data.password)
            return setMessage('Please provide a email number and password');
        dispatch(login(data.email, data.password));
    };

    useEffect(() => {
        if (auth.user && auth.user._id)
            navigate('/candidats')
    }, [auth.loading]);

    return {
        message,
        setMessage,
        handleLogin,
        email,
        password
    }
}