import { DEFAULT_ERROR_MESSAGE, DEFAULT_SUCCESS_STATUS } from '../../../_const';
import { IEnterpriseCreate } from '../../../interfaces/IEnterpriseCreate';
import ServerResponse from '../../../interfaces/common/ServerResponse';
import { createEnterpriseManager, getEntreprisesFiltered, getEntreprisesIdAndLabel } from '../../../manager/entreprise';
import { changeMessageSnack } from '../Snack/actions';
import { ENTREPRISES_REQUEST, ENTREPRISES_SUCCESS, ENTREPRISES_FAILURE, ENTREPRISES_FILTERED, ENTREPRISE_UPDATED } from './actionTypes';

export const getEnterprises = (page: any): any => async (dispatch: any) => {
    try {
        dispatch({ type: ENTREPRISES_REQUEST });
        const response: ServerResponse = await getEntreprisesIdAndLabel(page);

        if (response.status !== DEFAULT_SUCCESS_STATUS) {
            return dispatch({
                type: ENTREPRISES_FAILURE,
                payload: response
            });
        }
        return dispatch({
            type: ENTREPRISES_SUCCESS,
            payload: response.data
        })
    } catch (error) {
        return dispatch({
            type: ENTREPRISES_FAILURE,
            payload: {
                message: DEFAULT_ERROR_MESSAGE
            }
        });
    }
}

export const createEnterprise = (data: IEnterpriseCreate): any => async (dispatch: any) => {
    try {
        dispatch({ type: ENTREPRISES_REQUEST });
        const response: ServerResponse = await createEnterpriseManager(data);
        dispatch(changeMessageSnack(response.message))
        if (response.status !== DEFAULT_SUCCESS_STATUS) {
            return dispatch({
                type: ENTREPRISES_FAILURE,
                payload: response
            });
        }
        return dispatch({
            type: ENTREPRISE_UPDATED,
            payload: response.data
        })
    } catch (error) {
        return dispatch({
            type: ENTREPRISES_FAILURE,
            payload: {
                message: DEFAULT_ERROR_MESSAGE
            }
        });
    }
}

export const searchEntreprise = (filter: any, page: any): any => async (dispatch: any) => {
    try {
        dispatch({ type: ENTREPRISES_REQUEST });

        const response: ServerResponse = await getEntreprisesFiltered(filter, page);
        if (response.status !== DEFAULT_SUCCESS_STATUS) {
            return dispatch({
                type: ENTREPRISES_FAILURE,
                payload: response
            });
        }
        return dispatch({
            type: ENTREPRISES_FILTERED,
            payload: response.data
        })
    } catch (error) {
        console.log("error searchEntreprise: ", error);
        return dispatch({
            type: ENTREPRISES_FAILURE,
            payload: {
                message: DEFAULT_ERROR_MESSAGE
            }
        });
    }
}