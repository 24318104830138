import { Method } from 'axios'

import { RequestConfigMethods, ConfigOptions, RequestOptions } from './types'
import { createOptions } from './create-options'
import { DELETE_METHOD, GET_METHOD, PATCH_METHOD, POST_METHOD, PUT_METHOD } from '../_const';
import AxiosConfig from '../config/axiosConfig';

export const createRequest = (): RequestConfigMethods => ({
    get: <T>(url: string, options?: RequestOptions): Promise<T> => {
        return request(GET_METHOD, url, {
            headers: options?.headers,
            params: options?.params
        }, {});
    },

    post: <T>(url: string, options?: RequestOptions): Promise<T> => {
        return request(POST_METHOD, url, {
            headers: options?.headers,
            params: options?.params,
            body: options?.body
        }, {});
    },

    put: <T>(url: string, options?: RequestOptions): Promise<T> => {
        return request(PUT_METHOD, url, {
            headers: options?.headers,
            params: options?.params,
            body: options?.body
        }, {});
    },

    patch: <T>(url: string, options?: RequestOptions): Promise<T> => {
        return request(PATCH_METHOD, url, {
            headers: options?.headers,
            params: options?.params,
            body: options?.body
        }, {})
    },

    delete: <T>(url: string, options?: RequestOptions): Promise<T> => {
        return request(DELETE_METHOD, url, {
            headers: options?.headers,
            params: options?.params,
            body: {}
        }, {});
    }
})

const request = <T>(method: Method, url: string, options: RequestOptions, config: ConfigOptions): Promise<T> => {
    return AxiosConfig(createOptions(method, url, options, config)).then((response) => response.data);
}