
import { CANDIDATS_SUCCESS, CANDIDATS_LOADING, CANDIDATS_FAILURE, CANDIDATS_FILTERED, CANDIDATS_UPDATED } from './actionTypes'
import { ISelectReduxState } from '../../../interfaces/ISelectReduxState';


const initialState: ISelectReduxState = {
    data: null,
    loading: false,
    error: null,
};

export const candidatsReducer = (state = initialState, action: any): ISelectReduxState => {
    switch (action.type) {
        case CANDIDATS_LOADING:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case CANDIDATS_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: null,
            };
        case CANDIDATS_UPDATED:
            return {
                ...state,
                data: state.data.concat(action.payload),
                loading: false,
                error: null
            }
        case CANDIDATS_FILTERED:
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: null,
            };
        case CANDIDATS_FAILURE:
            return {
                ...state,
                data: null,
                loading: false,
                error: action.error,
            };
        default:
            return state;
    }
};