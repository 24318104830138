import { DEFAULT_ERROR_MESSAGE, DEFAULT_SUCCESS_STATUS } from '../../../_const';
import ServerResponse from '../../../interfaces/common/ServerResponse';
import { createMissionManager, getMissionsById, getMissionsEntreprisesFiltered } from '../../../manager/mission';
import { changeMessageSnack } from '../Snack/actions';
import { MISSIONS_REQUEST, MISSIONS_SUCCESS, MISSIONS_FAILURE, MISSIONS_UPDATED } from './actionTypes';

export const getMissionsByIdEnterprise = (enterpriseId: any, page: any): any => async (dispatch: any) => {
    try {
        dispatch({ type: MISSIONS_REQUEST });
        const response: ServerResponse = await getMissionsById(enterpriseId, page);
        if (response.status !== DEFAULT_SUCCESS_STATUS) {
            return dispatch({
                type: MISSIONS_FAILURE,
                payload: response
            });
        }
        return dispatch({
            type: MISSIONS_SUCCESS,
            payload: response.data
        })
    } catch (error) {
        return dispatch({
            type: MISSIONS_FAILURE,
            payload: {
                message: DEFAULT_ERROR_MESSAGE
            }
        });
    }
}

export const createMission = (data: any): any => async (dispatch: any) => {
    try {
        dispatch({ type: MISSIONS_REQUEST });
        const response: ServerResponse = await createMissionManager(data);
        dispatch(changeMessageSnack(response.message))
        if (response.status !== DEFAULT_SUCCESS_STATUS) {
            return dispatch({
                type: MISSIONS_FAILURE,
                payload: response
            });
        }
        return dispatch({
            type: MISSIONS_UPDATED,
            payload: response.data
        })
    } catch (error) {
        return dispatch({
            type: MISSIONS_FAILURE,
            payload: {
                message: DEFAULT_ERROR_MESSAGE
            }
        });
    }
}

export const searchMission = (enterpriseId: any, filter: any, page: any): any => async (dispatch: any) => {
    try {
        dispatch({ type: MISSIONS_REQUEST });
        const response: ServerResponse = await getMissionsEntreprisesFiltered(enterpriseId, filter, page);
        if (response.status !== DEFAULT_SUCCESS_STATUS) {
            return dispatch({
                type: MISSIONS_FAILURE,
                payload: response
            });
        }
        return dispatch({
            type: MISSIONS_SUCCESS,
            payload: response.data
        })
    } catch (error) {
        console.log("error searchMission: ", error);
        return dispatch({
            type: MISSIONS_FAILURE,
            payload: {
                message: DEFAULT_ERROR_MESSAGE
            }
        });
    }
}