import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, AUTH_FAILURE, AUTH_RESPONSE, LOGOUT } from './actionTypes';
import ServerResponse from '../../../interfaces/common/ServerResponse';
import * as AuthManager from '../../../manager/auth';
import { DEFAULT_ERROR_MESSAGE, DEFAULT_SUCCESS_STATUS } from '../../../_const';

export const loginRequest = () => {
    return {
        type: LOGIN_REQUEST,
    };
};

export const loginSuccess = (payload: any) => {
    return {
        type: LOGIN_SUCCESS,
        payload,
    };
};

export const loginFailure = (error: string) => {
    return {
        type: LOGIN_FAILURE,
        error,
    };
};

export const login = (email: string, password: string): any => async (dispatch: any) => {
    try {
        dispatch({ type: LOGIN_REQUEST });
        const response: ServerResponse = await AuthManager.login(email, password);
        if (response.status !== DEFAULT_SUCCESS_STATUS) {
            return dispatch({
                type: LOGIN_FAILURE,
                payload: response
            });
        }
        return dispatch({
            type: LOGIN_SUCCESS,
            payload: response.data
        })
    } catch (error) {
        return dispatch({
            type: LOGIN_FAILURE,
            payload: {
                message: DEFAULT_ERROR_MESSAGE
            }
        });
    }
}

export const isUserConnected = (): any => async (dispatch: any) => {
    try {
        dispatch({ type: LOGIN_REQUEST });
        const response: ServerResponse = await AuthManager.isConnected()
        if (response.status !== DEFAULT_SUCCESS_STATUS) {
            return dispatch({
                type: LOGIN_FAILURE,
                payload: response
            })
        }
        return dispatch({
            type: AUTH_RESPONSE,
            payload: response.data
        })
    } catch (error) {
        return dispatch({
            type: AUTH_FAILURE,
            payload: {
                message: DEFAULT_ERROR_MESSAGE
            }
        })
    }
}

export const logout = (): any => async (dispatch: any) => {
    try {
        dispatch({ type: LOGIN_REQUEST });
        const response: ServerResponse = await AuthManager.logout();
        if (response.status !== DEFAULT_SUCCESS_STATUS) {
            return dispatch({
                type: LOGIN_FAILURE,
                payload: response
            });
        }
        return dispatch({
            type: LOGOUT,
            payload: response.data
        })
    } catch (error) {
        return dispatch({
            type: LOGIN_FAILURE,
            payload: {
                message: DEFAULT_ERROR_MESSAGE
            }
        });
    }
}

