import React from "react";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useLogin } from "../../hooks/useLogin";
import "./Login.scss"
// @ts-ignore 
import logo from "../../assets/Images/brisin-logo-white.webp"

const defaultTheme = createTheme();

const Login: React.FC = () => {

    const { handleLogin, message, setMessage } = useLogin()

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const email = data.get('email');
        const password = data.get('password');

        if (email && password) {
            const user = {
                email: email as string,
                password: password as string,
            }
            handleLogin(user);
        } else {
            setMessage('Email and password are required.');
        }
    };

    return (
        <div className="login-container">
            <ThemeProvider theme={defaultTheme}>
                <Container component="main" maxWidth="xs">
                    <CssBaseline />
                    <Box
                        sx={{
                            marginTop: 8,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <img src={logo} alt="logo brisintemporal" />
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        {message && <Typography color="error">{message}</Typography>}
                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{
                            mt: 1, '&:focus': {
                                backgroundColor: "red"
                            }
                        }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                label="Adresse Email"
                                name="email"
                                autoComplete="email"
                            />
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                name="password"
                                label="Mot de passe"
                                type="password"
                                id="password"
                                autoComplete="current-password"
                            />
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{
                                    mt: 3, mb: 2, backgroundColor: '#ECCC1E', '&:hover': {
                                        backgroundColor: '#ECCC1E',
                                        color: '#5254C2',
                                        opacity: 1
                                    },
                                }}
                            >
                                Sign In
                            </Button>
                        </Box>
                    </Box>
                </Container>
            </ThemeProvider>
        </div>
    )
}

export default Login;