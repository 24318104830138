import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCandidats, searchCandidat } from "../_redux/reducers/Candidat/actions";
import { GridRowSelectionModel } from "@mui/x-data-grid";
import { changeFilter } from "../_redux/reducers/filter/actions";

export const useCandidats = () => {
    const candidats = useSelector((state: any) => state.candidats.data);
    const [page, setPage] = useState<any>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const [candidatsArray, setCandidatsArray] = useState<any>([]);
    const dispatch = useDispatch();
    let radioChecked: GridRowSelectionModel = [candidatsArray[0]?.id ?? ""];
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>(radioChecked);
    const filter = useSelector((state: any) => state.filter.filter)
    const candidatSelected = useSelector((state: any) => state.candidatSelected._id)

    useEffect(() => {
        if (!filter) {
            dispatch(getCandidats(page));
        } else {
            dispatch(searchCandidat(filter, page));
        }
    }, [page]);

    useEffect(() => {
        setPage(0)
    }, [filter])

    useEffect(() => {
        if (candidats)
            setCandidatsArray(candidats.map((entry: any) => {
                return {
                    ...entry,
                    id: entry._id
                }
            }));
    }, [candidats]);

    const searchCandidats = (filter: string, page: number) => {
        dispatch(changeFilter(filter))
        if (!filter) {
            dispatch(getCandidats(page));
        } else {
            dispatch(searchCandidat(filter, page));
        }
    };

    const handleChangePage = (event: any, newPage: number) => {
        setPage(newPage);
    };
    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>, id: string) => {
        const isChecked = event.target.checked;
        setSelectionModel((prevSelection) => {
            if (isChecked) {
                return [id];
            } else {
                return [];
            }
        });
    };

    useEffect(() => {
        setSelectionModel(candidatSelected)
    }, [candidatSelected])

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return {
        page,
        handleChangePage,
        rowsPerPage,
        candidatsArray,
        handleChangeRowsPerPage,
        searchCandidats,
        handleRadioChange,
        selectionModel,
        candidats,
        candidatSelected
    }
}