import { useDispatch, useSelector } from "react-redux"
import { missionFields } from "../assets/Data/data.js";
import { IFormFields } from "../interfaces/IFormFields";
import { changeDataMission, changeSelectMissionForm } from "../_redux/reducers/Form/mission/actions";
import { createMission } from "../_redux/reducers/Mission/actions";
import { checkNonEmptyStrings } from "../Utils";
import { changeMessageSnack } from "../_redux/reducers/Snack/actions";
import { useEffect, useState } from "react";
import { ALL_FIELDS_REQUIRED } from "../_const.js";

export const useMissionForm = () => {
    const fields = missionFields;
    const missionFieldsRedux = useSelector((state: any) => state.mission)
    const dispatch = useDispatch()
    const entrepriseId = useSelector((state: any) => state.entrepriseSelected._id)
    const beginDate = useSelector((state: any) => state.mission.begin_date)
    const endDate = useSelector((state: any) => state.mission.end_date)
    const [displayError, setDisplayError] = useState<any>(undefined)
    const morningHoursTimeBegin = useSelector((state: any) => state.mission.begin_time_morning)
    const morningHoursTimeEnd = useSelector((state: any) => state.mission.end_time_morning)
    const afternoonHoursTimeBegin = useSelector((state: any) => state.mission.begin_time_afternoon)
    const afternoonHoursTimeEnd = useSelector((state: any) => state.mission.end_time_afternoon)
    const [displayErrorHoursWork, setDisplayErrorHoursWork] = useState<any>(undefined)

    useEffect(() => {
        let beginTime = new Date(beginDate).getTime();
        let endTime = new Date(endDate).getTime();
        setDisplayError(beginTime > endTime);
    }, [beginDate, endDate])

    useEffect(() => {
        const hasError =
            morningHoursTimeBegin > morningHoursTimeEnd ||
            morningHoursTimeBegin > afternoonHoursTimeBegin ||
            morningHoursTimeBegin > afternoonHoursTimeEnd ||
            morningHoursTimeEnd > afternoonHoursTimeBegin ||
            morningHoursTimeEnd > afternoonHoursTimeEnd ||
            afternoonHoursTimeBegin > afternoonHoursTimeEnd;
        setDisplayErrorHoursWork(hasError);
    }, [morningHoursTimeBegin, morningHoursTimeEnd, afternoonHoursTimeBegin, afternoonHoursTimeEnd])

    const missionFieldsWithEnterpriseId = {
        ...missionFieldsRedux,
        enterprise_id: entrepriseId,
    };

    const handleSelectChange = (_event: any, newValue: any) => {
        dispatch(changeSelectMissionForm(newValue))
    }

    const setFieldData = (data: IFormFields) => {
        dispatch(changeDataMission(data))
    }

    const createMissionDispatch = () => {
        const isValid = checkNonEmptyStrings(missionFieldsWithEnterpriseId)
        if (!isValid) {
            dispatch(changeMessageSnack("Tous les champs sont requis"))
            return;
        }
        dispatch(createMission(missionFieldsWithEnterpriseId))
    }

    return {
        fields,
        handleSelectChange,
        setFieldData,
        createMissionDispatch,
        displayError,
        displayErrorHoursWork
    }
}