import { CANDIDAT_DESELECTED, CANDIDAT_SELECTED } from "../../../../_const";
import { ICandidatSelected } from "../../../../interfaces/ICandidatSelected";

const initialState: ICandidatSelected = {
    name: "",
    surname: "",
    _id: ""
}

export const candidatSelectedReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case CANDIDAT_SELECTED:
            return {
                ...state,
                name: action.payload.name,
                surname: action.payload.surname,
                _id: action.payload._id,
            }
        case CANDIDAT_DESELECTED:
            return initialState

        default:
            return state;
    }

}